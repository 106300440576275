import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Event, EventSeries } from '@mazboletos/shared/domain';
import { injectApiUrlV2 } from 'core-tokens';

@Injectable()
export class EventsHomeApiService {
  #apiUrl = injectApiUrlV2();
  #http = inject(HttpClient);

  getHome() {
    return this.#http.get<{ events: Event[]; event_series: EventSeries[] }>(`${this.#apiUrl}/home`);
  }
}
