import { inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@mazboletos/selling-point/core/config';
import { HttpClient } from '@angular/common/http';
import { ApiResponse, EventSeries } from '@mazboletos/shared/domain';
import { map } from 'rxjs';
import { requiresFirebaseToken, requiresToken } from '@mazboletos/shared/core/http/interceptors';
import { injectApiUrlV2 } from 'core-tokens';

@Injectable()
export class EventSeriesApiService {
  #apiUrl = `${injectApiUrlV2()}/event-series`;
  #http = inject(HttpClient);

  findBySlug(slug: string) {
    return this.#http.get<ApiResponse<EventSeries>>(`${this.#apiUrl}/${slug}`).pipe(map(({ data }) => data));
  }

  getAll() {
    return this.#http
      .get<ApiResponse<EventSeries[]>>(`${this.#apiUrl}`, {
        context: requiresToken(requiresFirebaseToken()),
      })
      .pipe(map(({ data }) => data));
  }
}
